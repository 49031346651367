<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card-code
          title="Data Member"
          no-body
        >
          <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="3"
                label-for="sortBySelect"
                class="mr-1 mb-md-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                  >
                    <template #first>
                      <option value="">
                        none
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                  >
                    <option :value="false">
                      ASC
                    </option>
                    <option :value="true">
                      DESC
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <!-- filter -->
              <b-form-group
                label="Filter"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card-body>

          <b-table
            striped
            hover
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(name)="data">
              <b-avatar v-if="data.item.logo !== null"
                class="mr-50"
                :src="`https://api.the-netwerk.com/public/storage/member/profile/${data.item.logo}`"
              /> 
              <b-avatar v-else
                class="mr-50"
                variant="primary"
                :text="data.item.name | initial"
              />
              {{ data.item.name }}
            </template>

            <template #cell(actions)="data">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="gotoDetail(data.item.id)">
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                      style="margin-top: -5px"
                    />
                    <span>Detail</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card-code>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
	import {
		BRow, BCol,
		BTable, BAvatar, BBadge, BCardBody, VBModal,
		BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, 
		BDropdown, BDropdownItem, BOverlay
	} from 'bootstrap-vue'
	import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
  import Ripple from 'vue-ripple-directive'
  
  export default {
    components: {
			BCardCode,
			BRow, BCol,
			BTable, BAvatar, BBadge, BCardBody, VBModal,
			BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, 
			BDropdown, BDropdownItem, BOverlay,
			StatisticCardHorizontal
		},
    directives: {
      Ripple,
    },
    data() {
      return {
        perPage: 50,
				pageOptions: [10, 50, 100],
				totalRows: 1,
				currentPage: 1,
				sortBy: '',
				sortDesc: false,
				sortDirection: 'asc',
				filter: null,
				filterOn: [],
				infoModal: {
					id: 'info-modal',
					title: '',
					content: '',
				},
				fields: [
					{ key: 'name', label: 'Name', sortable: true },
					{ key: 'phone', label: 'Phone' },
					{ key: 'email', label: 'Email', sortable: true },
					{ key: 'actions', label: 'Actions' }
				],
				items: [],
        active_role: null
      }
    },
		computed: {
			sortOptions() {
				// Create an options list from our fields
				return this.fields
					.filter(f => f.sortable)
					.map(f => ({ text: f.label, value: f.key }))
			},
		},
		created() {
      this.checkAuth()
    },
		mounted() {
      this.active_role = localStorage.getItem('role')
			this.getDataMember()
		}, 
		filters: {
			initial: function (val) {
				var result = "";
				let tokens = val.split("")

				if(tokens.length == 1) {
					result = tokens[0].substring(0,1).toUpperCase()
				} else {
					for(let i=0; i<2; i++){
						result += tokens[i].substring(0,1).toUpperCase()
					}
				}

				return result
			}
		},
    methods: {
			info(item, index, button) {
				this.infoModal.title = `Row index: ${index}`
				this.infoModal.content = JSON.stringify(item, null, 2)
				this.$root.$emit('bv::show::modal', this.infoModal.id, button)
			},
			resetInfoModal() {
				this.infoModal.title = ''
				this.infoModal.content = ''
			},
			onFiltered(filteredItems) {
				// Trigger pagination to update the number of buttons/pages due to filtering
				this.totalRows = filteredItems.length
				this.currentPage = 1
			},

			async getDataMember() {
        let cid = localStorage.getItem('client_id')
				
				let dataAPI = null
        if(this.active_role == 'corporate') {
          const config = {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
          }
          dataAPI = await this.$axios.get(`${cid}/member/corporate/user`, config)
        } else {
          dataAPI = await this.$axios.get(`${cid}/noauth/member`)
        }

				let members = dataAPI.data.data
				
        members.forEach(item => {
					let memberMiddleName = ""
					let memberLastName = ""

					if(item.member_middle_name) {
						memberMiddleName = ` ${item.member_middle_name}`
					}

					if(item.member_last_name) {
						memberLastName = ` ${item.member_last_name}`
					}

          let memberName = `${item.member_first_name}${memberMiddleName}${memberLastName}`

					let phoneCode = null
					let phone = null
					if(item.member_phone) {
						phoneCode = (item.phone_code === null ? "" : "+"+item.phone_code)
						phone = `${phoneCode}${item.member_phone}`
					} else {
						phone = "-"
					}

					let email = null
					let role_type = localStorage.getItem('role');
					if(role_type == 'corporate') {
						email = item.member_email
					} else {
						if(item.user !== null) {
							email = item.user.email
						} else {
							email = "-"
						}
					}

					let memberItem = {
						id: item.id,
						name: memberName,
						logo: item.logo,
            phone: phone,
            email: email,
            status: item.member_status
					}

					this.items.push(memberItem)
				})
				
				// Set total status member
				this.setTotalStatusMember(members)

				// Set the initial number of items
				this.totalRows = this.items.length

				this.overlayUserMember = false
			},

      gotoDetail(id) {
        this.$router.push({ path: `/event/member-log/${id}` })
      }
    }
  }
</script>